import useSWR from "swr";
import { fetcher } from "../fetcher";
import { FetchError } from "@/components/error";
import { Loading } from "@/components/loading";
import {
  SheetHeader,
  SheetTitle,
  SheetDescription,
} from "@/components/ui/sheet";
import { useTranslation } from "react-i18next";
import { FiltersSelector } from "@/components/sheet/filters-selector";
import { Task } from "@/types/task";
import { endOfDay, format, startOfDay } from "date-fns";
import { enUS, es } from "date-fns/locale";
import { TaskList } from "@/components/calendar/ui/render";

export function DayDetails({
  id,
  filters,
}: {
  id: string;
  filters: {
    pending: boolean;
    inProgress: boolean;
    done: boolean;
  };
}) {
  const { t } = useTranslation();

  const { data, error } = useSWR<{
    tasks: Task[];
  }>(
    `/v1/dashboard/calendar/range/${startOfDay(
      new Date(id)
    ).getTime()}/${endOfDay(new Date(id)).getTime()}`,
    fetcher
  );

  if (error)
    return (
      <>
        <SheetTitle className="hidden">{t("sheet.fetch.title")}</SheetTitle>
        <SheetDescription className="hidden">
          {t("sheet.fetch.description")}
        </SheetDescription>
        <FetchError />
      </>
    );
  if (!data)
    return (
      <>
        <SheetTitle className="hidden">{t("sheet.load.title")}</SheetTitle>
        <SheetDescription className="hidden">
          {t("sheet.load.description")}
        </SheetDescription>
        <Loading />
      </>
    );

  const { tasks } = data;

  return (
    <>
      <SheetHeader>
        <SheetTitle className="text-xl">
          {format(new Date(id), "dd MMMM yyyy", {
            locale: t("lang") === "es" ? es : enUS,
          })}
        </SheetTitle>
        <SheetDescription>
          {t("sheet.day.stats", {
            progress: tasks.filter((task) => task.status === "IN_PROGRESS")
              .length,
            pending: tasks.filter((task) => task.status === "PENDING").length,
            completed: tasks.filter((task) => task.status.startsWith("DONE"))
              .length,
            total: tasks.length,
          })}
        </SheetDescription>
      </SheetHeader>
      <section className="mt-4">
        <FiltersSelector />
        <div className="mt-4">
          <TaskList tasks={tasks} filters={filters} displayAsCalendar />
        </div>
      </section>
    </>
  );
}
