import { FetchError } from "@/components/error";
import { Loading } from "@/components/loading";
import { Input } from "@/components/ui/input";
import { fetcher } from "@/lib/fetcher";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { Link, Route, Switch } from "wouter";
import { MessageCircleIcon } from "lucide-react";
import { FiltersSelector } from "@/components/sheet/filters-selector";
import { TaskCard } from "@/components/task";
import { useTaskSheet } from "@/lib/sheet";
import { Task } from "@/types/task";

interface ProjectData {
  id: string;
  name: string;
}

interface TaskInfo {
  DONE: Task[];
  IN_PROGRESS: Task[];
  PENDING: Task[];
}

function NoProject() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center h-full w-full">
      <MessageCircleIcon size={64} />
      <p className="scroll-m-20 pt-2 text-3xl font-semibold tracking-tight first:mt-0">
        {t("projects.no_project")}
      </p>
    </div>
  );
}

function ProjectData({ id }: { id: string }) {
  const { t } = useTranslation();

  const { controlSheet, filters } = useTaskSheet();

  const { data, error } = useSWR<{
    project: ProjectData;
    taskInfo: TaskInfo;
  }>(`/v1/dashboard/projects/${id}`, fetcher);

  if (error) return <FetchError />;

  if (!data) return <Loading />;

  const { project, taskInfo } = data;

  return (
    <article className="pl-4 overflow-x-scroll">
      <div className="flex items-center gap-4">
        <div className="flex flex-col">
          <h3 className="text-3xl font-semibold tracking-tight">
            {project.name}
          </h3>
          <p>
            {t("projects.profile.stats", {
              completed: taskInfo.DONE.length,
              progress: taskInfo.IN_PROGRESS.length,
              pending: taskInfo.PENDING.length,
              total:
                taskInfo.DONE.length +
                taskInfo.IN_PROGRESS.length +
                taskInfo.PENDING.length,
            })}
          </p>
        </div>
      </div>
      <h3 className="my-6 text-3xl font-semibold tracking-tight first:mt-0">
        {t("projects.profile.open")}
      </h3>
      <div>
        <FiltersSelector />
        <div className="mt-4">
          {[
            ...[filters.pending && "PENDING"],
            ...[filters.inProgress && "IN_PROGRESS"],
            ...[filters.done && "DONE"],
          ].map((status) =>
            taskInfo[status as keyof TaskInfo]?.map((task, idx) => {
              return (
                <TaskCard
                  key={idx}
                  task={task}
                  detail="title"
                  onClick={() => controlSheet.openWithTask(task.id)}
                />
              );
            })
          )}
        </div>
      </div>
    </article>
  );
}

export function Projects() {
  const { t } = useTranslation();

  const [search, setSearch] = useState<string>("");

  const { data, error } = useSWR<{ projects: ProjectData[] }>(
    "/v1/dashboard/projects",
    fetcher
  );

  if (error) return <FetchError />;

  if (!data) return <Loading />;

  const { projects } = data;

  const projectsData = projects.filter((project) =>
    project.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <section className="flex justify-between h-full max-h-full">
      <div className="w-1/3 h-full overflow-y-scroll">
        <Input
          placeholder={t("projects.search.placeholder")}
          value={search}
          onChange={(val) => setSearch(val.target.value)}
        />
        <div className="flex flex-col gap-2">
          {projectsData.map((project) => (
            <Link
              key={project.id}
              href={`/${project.id}`}
              className="flex items-center gap-2 p-2 hover:bg-gray-100 dark:hover:bg-gray-800/40 rounded-md transition-colors"
            >
              <p className="font-semibold text-lg">{project.name}</p>
            </Link>
          ))}
        </div>
      </div>
      <div className="w-2/3 h-full border-l">
        <Switch>
          <Route path="/:id">{({ id }) => <ProjectData id={id} />}</Route>
          <Route path="/">
            <NoProject />
          </Route>
        </Switch>
      </div>
    </section>
  );
}
