import { CheckIcon, EllipsisIcon, XIcon } from "lucide-react";
import { Task } from "@/types/task";

interface TaskCountsProps {
  tasks: Task[];
  filters: {
    done: boolean;
    inProgress: boolean;
    pending: boolean;
  };
}

export function TaskCounts({ tasks, filters }: TaskCountsProps) {
  const statusIcons = {
    DONE: <CheckIcon size={16} />,
    IN_PROGRESS: <EllipsisIcon size={16} />,
    PENDING: <XIcon size={16} />,
  };

  const statusFilters = {
    DONE: filters.done,
    IN_PROGRESS: filters.inProgress,
    PENDING: filters.pending,
  };

  return (
    <div className="ml-2 flex gap-1">
      {(
        Object.entries(statusFilters) as [keyof typeof statusIcons, boolean][]
      ).map(([status, isEnabled]) => {
        const count = tasks.filter((task) =>
          task.status.startsWith(status)
        ).length;

        if (isEnabled && count > 0) {
          return (
            <div key={status} className="flex items-center gap-1">
              {statusIcons[status]}
              <p>{count}</p>
            </div>
          );
        }

        return null;
      })}
    </div>
  );
}
