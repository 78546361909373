import { useState, useRef, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import {
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { Task } from "@/types/task";
import { TaskCard } from "@/components/task";
import {
  ChevronLeftIcon,
  Clock,
  Files,
  Kanban,
  LetterText,
  PersonStanding,
  Pin,
  PlusCircle,
  XIcon,
} from "lucide-react";
import { Link } from "wouter";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useDeadline } from "@/hooks/deadline";
import { baseURL } from "@/globals";
import { Separator } from "@/components/ui/separator";
import { authedFetch, fetcher } from "@/lib/fetcher";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectValue,
  SelectTrigger,
  SelectItem,
  SelectContent,
} from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Textarea } from "@/components/ui/textarea";
import useSWR from "swr";
import { Loading } from "@/components/loading";
import { FetchError } from "@/components/error";
import { cn } from "../utils";
import { format, isBefore } from "date-fns";
import { enUS, es } from "date-fns/locale";
import { useAuth } from "../auth";

function EditTaskState({ task, done }: { task: Task; done: () => void }) {
  const { user } = useAuth();

  const isManager =
    ["OWNER", "ADMIN", "SUPERADMIN"].includes(user?.role!) ||
    user?.id === task.assigned_by_id;

  const { t } = useTranslation();

  const { toast } = useToast();

  const options = ["PENDING", "IN_PROGRESS", "DONE", "DONE_WITH_ISSUES"];

  const schema = z
    .object({
      status: z.string().refine((val) => options.includes(val)),
      note: z.string().optional(),
      final_files: z.array(z.string()).optional(),
    })
    .refine(
      (val) => {
        if (
          val.status.startsWith("DONE") &&
          (!val.note || val.note.length < 1)
        ) {
          return false;
        }

        return true;
      },
      { message: "sheet.task.edit.note_required", path: ["status"] }
    );

  type SchemaType = z.infer<typeof schema>;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
    setValue,
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
    defaultValues: {
      status: task.status,
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const status = watch("status");
  const files = watch("final_files");

  const fileRef = useRef<HTMLInputElement>(null);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [filesData, setFilesData] = useState<{ name: string; key: string }[]>(
    []
  );

  async function uploadFile(e: ChangeEvent<HTMLInputElement>) {
    if (!e.target.files || isFileLoading) {
      return;
    }

    setIsFileLoading(true);

    const file = e.target.files[0];

    const { type, name } = file;

    try {
      const payloadReq = await authedFetch("/v1/blob/file", {
        method: "POST",
        body: JSON.stringify({
          type,
          ext: name.split(".").pop(),
          name: name.split(".").slice(0, -1).join("."),
        }),
      });

      const { signedUrl, key } = await payloadReq.json();

      const fff = await fetch(signedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": type,
        },
        body: file,
      });

      if (!fff.ok) {
        throw new Error("File upload failed");
      }

      setValue("final_files", [...(files ?? []), key]);

      setFilesData([...filesData, { name, key }]);

      setIsFileLoading(false);
    } catch (e) {
      toast({ title: t("generic.error") });
      setIsFileLoading(false);
    }
  }

  const onSubmit: SubmitHandler<SchemaType> = async (data) => {
    try {
      setIsLoading(true);

      await authedFetch(`/v1/task/status/${task.id}`, {
        method: "PATCH",
        body: JSON.stringify({ ...data, lang: t("lang") }),
      });

      done();
    } catch (e) {
      setError("root", {
        message: "generic.error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
      <p>{t(`sheet.task.edit.title`)}</p>

      <div className="grid gap-2">
        <Label htmlFor="state">{t(`sheet.task.edit.state`)}</Label>

        <Select onValueChange={(val) => setValue("status", val)} value={status}>
          <SelectTrigger id="state">
            <SelectValue
              placeholder={t(`sheet.task.edit.select.placeholder`)}
            />
          </SelectTrigger>
          <SelectContent>
            {options.map((option, idx) => (
              <SelectItem value={option} key={idx}>
                {t(`sheet.task.edit.select.${option}`)}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        {errors.status && (
          <p className="text-sm text-red-500">
            {t(errors.status.message ?? "generic.error")}
          </p>
        )}
      </div>

      {(status ?? "").startsWith("DONE") && task.needs_manager && !isManager ? (
        <p className="text-green-700">{t("tasks.needs_manager")}</p>
      ) : (
        <>
          {status && status.length > 1 && status.startsWith("DONE") && (
            <>
              <div className="grid gap-2">
                <Label htmlFor="note">{t(`sheet.task.edit.note`)}</Label>
                <Textarea
                  {...register("note")}
                  id="note"
                  className="p-2 border border-slate-200 rounded"
                  placeholder={t(`sheet.task.edit.note_placeholder`)}
                />

                {errors.note && (
                  <p className="text-sm text-red-500">
                    {t(errors.note.message ?? "generic.error")}
                  </p>
                )}
              </div>

              <div className="flex flex-col gap-2">
                <Label htmlFor="attached_files">
                  {t("tasks.create.form.attached_files.label")}
                </Label>

                {filesData.map((file, i) => (
                  <div className="flex items-center gap-2" key={i}>
                    <a
                      href={`${baseURL}/v1/blob/file/${file.key}`}
                      key={i}
                      className="underline hover:no-underline text-purple-500"
                      target="_BLANK"
                    >
                      <p>{file.name}</p>
                    </a>
                    <XIcon
                      className="w-6 h-6 cursor-pointer"
                      onClick={() => {
                        setValue(
                          "final_files",
                          (files ?? []).filter(
                            (attachedFile: any) => attachedFile !== file.key
                          )
                        );
                        setFilesData(
                          filesData.filter(
                            (attachedFile) => attachedFile.key !== file.key
                          )
                        );
                      }}
                    />
                  </div>
                ))}

                <input
                  type="file"
                  ref={fileRef}
                  className="hidden"
                  onChange={uploadFile}
                />

                <Button
                  disabled={isFileLoading}
                  onClick={() => fileRef.current!.click()}
                  type="button"
                >
                  {t(
                    isFileLoading
                      ? "tasks.create.form.attached_files.loading"
                      : "tasks.create.form.attached_files.add"
                  )}
                </Button>
              </div>
            </>
          )}

          {errors.root && (
            <p className="text-sm text-red-500">
              {t(errors.root.message ?? "generic.error")}
            </p>
          )}

          <Button type="submit" disabled={isLoading || status === task.status}>
            {t(`sheet.task.edit.submit`)}
          </Button>
        </>
      )}
    </form>
  );
}

function DueIn({ task }: { task: Task }) {
  const { t } = useTranslation();

  const isDone = task.status.startsWith("DONE");

  const duedin = useDeadline(
    new Date(isDone ? task.finished_at ?? new Date().getTime() : task.deadline),
    isDone
  );

  const isOverdue = isBefore(
    new Date(task.deadline),
    task.status.startsWith("DONE")
      ? new Date(task.finished_at ?? new Date().getTime())
      : new Date()
  );

  const colors = {
    PENDING: "gray",
    IN_PROGRESS: "yellow",
    DONE: "green",
    DONE_WITH_ISSUES: "orange",
  };

  return (
    <div className="flex items-center gap-2">
      <div
        className={cn(
          `bg-${colors[task.status]}-500`,
          isOverdue && `border-l-8 border-red-600`,
          `w-4 h-4 rounded`
        )}
      />
      <SheetDescription>
        {t(`sheet.task.status.${task.status}`, { deadline: duedin })}
      </SheetDescription>
    </div>
  );
}

export function TaskDetails({
  id,
  canGoBack,
  goBack,
  nextTask,
  closeSheet,
}: {
  id: string;
  canGoBack: boolean;
  goBack: () => void;
  nextTask: (id: string) => void;
  closeSheet: () => void;
}) {
  const { t } = useTranslation();

  const { data, error, mutate } = useSWR<{ task: Task }>(
    `/v1/dashboard/info/task/${id}`,
    fetcher
  );

  if (error)
    return (
      <>
        <SheetTitle className="hidden">{t("sheet.fetch.title")}</SheetTitle>
        <SheetDescription className="hidden">
          {t("sheet.fetch.description")}
        </SheetDescription>
        <FetchError />
      </>
    );
  if (!data)
    return (
      <>
        <SheetTitle className="hidden">{t("sheet.load.title")}</SheetTitle>
        <SheetDescription className="hidden">
          {t("sheet.load.description")}
        </SheetDescription>
        <Loading />
      </>
    );

  const { task } = data;

  const locale = t("lang") === "es" ? es : enUS;

  return (
    <>
      <SheetHeader>
        {task.title && (
          <SheetTitle className="text-xl sm:text-xl">{task.title}</SheetTitle>
        )}
        <DueIn task={task} />
        {task.needs_manager && (
          <p className="text-green-700">{t("tasks.needs_manager")}</p>
        )}
      </SheetHeader>

      <Separator className="my-4" />

      {task.status.startsWith("DONE") && (
        <>
          <section className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <div className="flex items-center gap-1 font-regular">
                <LetterText className="h-4 w-4" />
                <p>{t("sheet.task.final.note")}</p>
              </div>

              {task?.note ? (
                <p>{task.note}</p>
              ) : (
                <p className="italic">{t("sheet.task.final.no")}</p>
              )}
            </div>

            {(task.final_files ?? []).length > 0 && (
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1 font-regular">
                  <Files className="h-4 w-4" />
                  <p>{t("sheet.task.final_files")}</p>
                </div>

                {task.final_files.map((file, key) => (
                  <a
                    href={`${baseURL}/v1/blob/file/${file.id}`}
                    key={key}
                    className="underline hover:no-underline text-purple-500"
                    target="_BLANK"
                  >
                    <p>{file.name}</p>
                  </a>
                ))}
              </div>
            )}
          </section>
          <Separator className="my-4" />
        </>
      )}

      <section className="flex flex-col gap-4">
        {canGoBack && (
          <div
            className="flex items-center hover:bg-slate-100 transition-colors cursor-pointer max-w-fit p-2 rounded border border-slate-200"
            onClick={goBack}
          >
            <ChevronLeftIcon className="w-6 h-6" />
            <p>{t("sheet.task.back")}</p>
          </div>
        )}

        {task.parent_task_id && task.parent_task && (
          <p
            onClick={() => nextTask(task.parent_task_id!)}
            className="underline hover:no-underline"
          >
            {t("sheet.task.parent", { name: task.parent_task.title })}
          </p>
        )}

        {task.deadline && (
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-1 font-regular">
              <Clock className="h-4 w-4" />
              <p>{t("sheet.task.deadline")}</p>
            </div>
            <p>{format(task.deadline, "dd MMMM yyyy hh:mm a", { locale })}</p>
          </div>
        )}

        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-1 font-regular">
            <LetterText className="h-4 w-4" />
            <p>{t("sheet.task.description")}</p>
          </div>

          {task?.description ? (
            <p>{task.description}</p>
          ) : (
            <p className="italic">{t("sheet.task.no_description")}</p>
          )}
        </div>

        {task.created_at && (
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-1 font-regular">
              <Clock className="h-4 w-4" />
              <p>{t("sheet.task.assigned")}</p>
            </div>
            <p>{format(task.created_at, "dd MMMM yyyy hh:mm a", { locale })}</p>
          </div>
        )}

        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-1 font-regular">
            <PersonStanding className="h-4 w-4" />
            <p>{t("sheet.task.people")}</p>
          </div>

          {task?.assigned_to ? (
            <Link
              href={`~/home/employees/${task?.assigned_to?.id}`}
              onClick={closeSheet}
              className="flex items-center gap-2 underline hover:no-underline"
            >
              {task?.assigned_to?.photo && (
                <Avatar className="h-8 w-8">
                  <AvatarImage src={task?.assigned_to?.photo} />
                  <AvatarFallback>
                    {task?.assigned_to?.name[0].toUpperCase()}
                  </AvatarFallback>
                </Avatar>
              )}
              <p>{t("sheet.task.to", { name: task.assigned_to.name })}</p>
            </Link>
          ) : (
            <p>{t("sheet.task.to", { name: t("dead") })}</p>
          )}

          {task?.assigned_by ? (
            <Link
              href={`~/home/employees/${task?.assigned_by?.id}`}
              onClick={closeSheet}
              className="flex items-center gap-2 underline hover:no-underline"
            >
              {task?.assigned_by?.photo && (
                <Avatar className="h-8 w-8">
                  <AvatarImage src={task?.assigned_by?.photo} />
                  <AvatarFallback>
                    {task?.assigned_by?.name[0].toUpperCase()}
                  </AvatarFallback>
                </Avatar>
              )}
              <p>{t("sheet.task.by", { name: task.assigned_by.name })}</p>
            </Link>
          ) : (
            <p>{t("sheet.task.by", { name: t("dead") })}</p>
          )}
        </div>

        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-1 font-regular">
            <Kanban className="h-4 w-4" />
            <p>{t("sheet.task.project")}</p>
          </div>

          {task?.assigned_in ? (
            <Link
              href={`~/home/projects/${task?.assigned_in?.id}`}
              onClick={closeSheet}
              className="underline hover:no-underline"
            >
              {t("sheet.task.in", { name: task.assigned_in.name })}
            </Link>
          ) : (
            <p>{t("sheet.task.in", { name: t("dead_any") })}</p>
          )}
        </div>

        {task?.written_ubication && (
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-1 font-regular">
              <Pin className="h-4 w-4" />
              <p>{t("sheet.task.ubication")}</p>
            </div>

            <a
              href={`https://maps.google.com?q=${task.lat!},${task.lng!}`}
              target="_BLANK"
              className="underline hover:no-underline text-purple-500"
            >
              {task.written_ubication}
            </a>
          </div>
        )}

        {(task.attached_files ?? []).length > 0 && (
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-1 font-regular">
              <Files className="h-4 w-4" />
              <p>{t("sheet.task.files")}</p>
            </div>

            {(task.attached_files ?? []).map((file, key) => (
              <a
                href={`${baseURL}/v1/blob/file/${file.id}`}
                key={key}
                className="underline hover:no-underline text-purple-500"
                target="_BLANK"
              >
                <p>{file.name}</p>
              </a>
            ))}
          </div>
        )}
      </section>

      <section className="mt-4">
        <div className="flex flex-col gap-4">
          <Separator />

          <EditTaskState task={task} done={() => mutate()} />

          <Separator />

          <div className="gap-4">
            <div className="flex items-center gap-1 font-regular">
              <PlusCircle className="h-4 w-4" />
              <p>{t("sheet.task.childs")}</p>
            </div>

            <Link
              className="underline hover:no-underline text-purple-500"
              href={`/tasks?parent_id=${task.id}`}
              onClick={closeSheet}
            >
              {t("sheet.task.new_child")}
            </Link>
            {task.childTasks.map((child, idx) => (
              <TaskCard
                key={idx}
                task={child}
                detail="user"
                onClick={() => nextTask(child.id)}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
