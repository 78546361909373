import { Task } from "@/types/task";
import { TaskCard } from "@/components/task";
import { useTaskSheet } from "@/lib/sheet";
import { Plus } from "lucide-react";
import { useTranslation } from "react-i18next";

interface TaskListProps {
  tasks: Task[];
  filters: {
    pending: boolean;
    inProgress: boolean;
    done: boolean;
  };
  reduced?: boolean;
  displayAsCalendar?: boolean;
  detail?: "title" | "user" | "full";
  scope?: "me" | "by-me";
  hideText?: boolean;
  canOpenTaskSheet?: boolean;
}

function TaskListAsCalendar({
  initTasks,
  detail = "user",
  scope = "by-me",
  canOpenTaskSheet = true,
}: {
  initTasks: Task[];
  detail?: "title" | "user" | "full";
  scope?: "me" | "by-me";
  canOpenTaskSheet?: boolean;
}) {
  const { controlSheet, isSheetOpen } = useTaskSheet();

  const tasksByHour = initTasks.reduce((acc, task) => {
    const hour = new Date(task.deadline).getHours();

    if (!acc[hour]) {
      acc[hour] = { hour, tasks: [] };
    }

    acc[hour].tasks.push(task);

    return acc;
  }, {} as Record<number, { hour: number; tasks: Task[] }>);

  return (
    <section>
      {Object.values(tasksByHour).map(({ hour, tasks }) => (
        <div key={hour}>
          <h3 className="scroll-m-20 text-2xl font-semibold tracking-tight my-2">
            {hour.toString().padStart(2, "0")}:00
          </h3>
          {tasks.map((task, idx) => (
            <TaskCard
              key={`${task.id}-${idx}`}
              task={task}
              detail={detail}
              scope={scope}
              onClick={() =>
                canOpenTaskSheet && isSheetOpen
                  ? controlSheet.navigateToTask(task.id)
                  : controlSheet.openWithTask(task.id)
              }
            />
          ))}
        </div>
      ))}
    </section>
  );
}

export function TaskList({
  tasks,
  filters,
  reduced = false,
  displayAsCalendar = false,
  detail = "user",
  scope = "by-me",
  hideText = false,
  canOpenTaskSheet = true,
}: TaskListProps) {
  const { t } = useTranslation();
  const { controlSheet, isSheetOpen } = useTaskSheet();

  const filteredStatuses = [
    ...(filters.pending ? ["PENDING"] : []),
    ...(filters.inProgress ? ["IN_PROGRESS"] : []),
    ...(filters.done ? ["DONE"] : []),
  ];

  const filteredTasks = filteredStatuses.flatMap((status) =>
    tasks.filter((task) => task.status.startsWith(status))
  );

  if (filteredTasks.length < 1) {
    if (hideText) return null;

    return <p>{t("generic.no-tasks")}</p>;
  }

  if (displayAsCalendar) {
    return (
      <TaskListAsCalendar
        initTasks={filteredTasks}
        detail={detail}
        scope={scope}
      />
    );
  }

  const tasksToShow = reduced ? filteredTasks.slice(0, 3) : filteredTasks;
  const hasMoreTasks = reduced && filteredTasks.length > 3;

  return (
    <div className="overflow-y-scroll">
      {tasksToShow.map((task, idx) => (
        <TaskCard
          key={`${task.id}-${idx}`}
          task={task}
          detail={detail}
          scope={scope}
          onClick={() =>
            canOpenTaskSheet && isSheetOpen
              ? controlSheet.navigateToTask(task.id)
              : controlSheet.openWithTask(task.id)
          }
        />
      ))}
      {hasMoreTasks && (
        <div className="flex items-center justify-center w-8 h-8 bg-gray-700 rounded-full">
          <Plus size={16} />
        </div>
      )}
    </div>
  );
}
