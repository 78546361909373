import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Label } from "@radix-ui/react-label";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import useSWR from "swr";
import { authedFetch, fetcher } from "@/lib/fetcher";
import { Loader } from "@/components/ui/loader";
import { FetchError } from "@/components/error";
import {
  Select,
  SelectItem,
  SelectContent,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { baseURL } from "@/globals";
import { useToast } from "@/components/ui/use-toast";
import { XIcon } from "lucide-react";
import { useLocation, useSearch } from "wouter";
import { validator } from "./validate";
import { LocationPicker } from "./location-picker";
import { Checkbox } from "@/components/ui/checkbox";
import { MultiSelect } from "@/components/ui/multi-select";

const template = {
  title: "",
  description: "",
  needs_manager: false,
  deadline: new Date("June 1, 2001").toISOString(),
  assigned_to_id: [],
  assigned_in_id: "",
  attached_files: [],
  childs: [],
};

function TaskComponent({
  task,
  setTask,
  removeTask,
  isChild = false,
  isError,
  projects,
}: {
  task: any;
  setTask: (task: any) => void;
  removeTask: () => void;
  isChild?: boolean;
  isError: boolean;
  projects: {
    id: string;
    name: string;
    photo: string;
    members: { id: string; name: string; photo: string; caption: string }[];
  }[];
}) {
  const { t } = useTranslation();

  const { toast } = useToast();

  function addChild() {
    setTask((prevTask: any) => ({
      ...prevTask,
      childs: [...prevTask.childs, structuredClone(template)],
    }));
  }

  function removeMe() {
    if (isChild) removeTask();
  }

  const [errors, setErrors] = useState<
    Record<string, string> | Record<string, never>
  >({});

  function validate() {
    const copy = structuredClone(task);

    delete copy.childs;

    const result = validator.safeParse(copy);

    if (!result.success) {
      setErrors(
        result.error.issues.reduce((acc: any, issue: any) => {
          acc[issue.path[0]] = issue.message;
          return acc;
        }, {})
      );
    }
  }

  useEffect(() => {
    if (isError) {
      validate();
    }
  }, [isError, task]);

  const fileRef = useRef<HTMLInputElement>(null);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [filesData, setFilesData] = useState<{ name: string; key: string }[]>(
    []
  );

  async function uploadFile(e: ChangeEvent<HTMLInputElement>) {
    if (!e.target.files || isFileLoading) {
      return;
    }

    setIsFileLoading(true);

    const file = e.target.files[0];

    const { type, name } = file;

    try {
      const payloadReq = await authedFetch("/v1/blob/file", {
        method: "POST",
        body: JSON.stringify({
          type,
          ext: name.split(".").pop(),
          name: name.split(".").slice(0, -1).join("."),
        }),
      });

      const { signedUrl, key } = await payloadReq.json();

      const fff = await fetch(signedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": type,
        },
        body: file,
      });

      if (!fff.ok) {
        throw new Error("File upload failed");
      }

      setTask((prevTask: any) => ({
        ...prevTask,
        attached_files: [...prevTask.attached_files, key],
      }));

      setFilesData([...filesData, { name, key }]);

      setIsFileLoading(false);
    } catch (e) {
      toast({ title: t("tasks.create.form.attached_files.upload_error") });
      setIsFileLoading(false);
    }
  }

  const [employees, setEmployees] = useState<
    { id: string; name: string; photo: string; caption: string }[]
  >([]);

  function updateChildTask(index: number, newChild: any) {
    setTask((prevTask: any) => ({
      ...prevTask,
      childs: prevTask.childs.map((child: any, i: any) =>
        i === index ? newChild : child
      ),
    }));
  }

  function removeChildTask(index: number) {
    setTask((prevTask: any) => ({
      ...prevTask,
      childs: prevTask.childs.filter((_: any, i: any) => i !== index),
    }));
  }

  const [time, setTime] = useState("");

  return (
    <div className="flex flex-col border-l-2 gap-4 px-4 my-4">
      <div className="flex flex-col gap-2">
        <Label htmlFor="title">{t("tasks.create.form.title.label")}</Label>

        <Input
          id="title"
          value={task.title}
          onChange={(e) => setTask({ ...task, title: e.target.value })}
          placeholder={t("tasks.create.form.title.placeholder")}
        />

        {errors.title && (
          <p className="text-sm text-red-500">
            {t(errors.title || "generic.error")}
          </p>
        )}
      </div>

      <div className="flex flex-col gap-2">
        <Label htmlFor="description">
          {t("tasks.create.form.description.label")}
        </Label>

        <Textarea
          id="description"
          value={task.description}
          onChange={(e) => setTask({ ...task, description: e.target.value })}
          placeholder={t("tasks.create.form.description.placeholder")}
        />

        {errors.description && (
          <p className="text-sm text-red-500">
            {t(errors.description || "generic.error")}
          </p>
        )}
      </div>

      <div className="flex items-center gap-2">
        <Checkbox
          name="needs_manager"
          value={task.needs_manager}
          onCheckedChange={(checked) =>
            setTask({ ...task, needs_manager: !!checked })
          }
        />
        <Label htmlFor="needs_manager">{t("tasks.needs_manager")}</Label>
      </div>

      <div className="flex flex-col gap-2">
        <Label htmlFor="deadline">
          {t("tasks.create.form.deadline.label")}
        </Label>

        <Input
          id="deadline"
          value={time}
          onChange={(e) => {
            setTime(e.target.value);
            setTask({
              ...task,
              deadline: new Date(e.target.value).toISOString(),
            });
          }}
          type="datetime-local"
        />

        {errors.deadline && (
          <p className="text-sm text-red-500">
            {t(errors.deadline || "generic.error")}
          </p>
        )}
      </div>

      <div className="flex flex-col gap-2">
        <Label htmlFor="assigned_in_id">
          {t("tasks.create.form.assigned_in_id.label")}
        </Label>

        <Select
          onValueChange={(e) => {
            setTask({ ...task, assigned_in_id: e, assigned_to_id: "" });
            setEmployees(projects.find((prj) => prj.id === e)!.members);
          }}
        >
          <SelectTrigger>
            <SelectValue
              placeholder={t("tasks.create.form.assigned_in_id.placeholder")}
            />
          </SelectTrigger>
          <SelectContent>
            {projects.map((project, key) => (
              <SelectItem key={key} value={project.id}>
                <div className="flex items-center gap-2">
                  <Avatar className="w-6 h-6">
                    <AvatarImage src={project.photo!} />
                    <AvatarFallback>
                      {project.name[0].toUpperCase()}
                    </AvatarFallback>
                  </Avatar>
                  <p>{project.name}</p>
                </div>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        {errors.assigned_in_id && (
          <p className="text-sm text-red-500">
            {t(errors.assigned_in_id || "generic.error")}
          </p>
        )}
      </div>

      {employees && employees.length > 0 && (
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-1">
            <Label htmlFor="assigned_to_id">
              {t("tasks.create.form.assigned_to_id.label")}
            </Label>
          </div>

          <MultiSelect
            options={employees.map((employee) => ({
              label: employee.name,
              value: employee.id,
              icon: () => (
                <Avatar className="w-6 h-6 mr-2">
                  <AvatarFallback>{employee.name.charAt(0)}</AvatarFallback>
                  <AvatarImage src={employee.photo} />
                </Avatar>
              ),
            }))}
            onValueChange={(selected) =>
              setTask({ ...task, assigned_to_id: selected })
            }
            value={task.assigned_to_id}
          />

          {errors.assigned_to_id && (
            <p className="text-sm text-red-500">
              {t(errors.assigned_to_id || "generic.error")}
            </p>
          )}
        </div>
      )}

      <div className="flex flex-col gap-2">
        <Label htmlFor="ubication">
          {t("tasks.create.form.ubication.label")}
        </Label>

        <LocationPicker
          onSelect={(written_ubication, lat, lng) => {
            const location = {
              written_ubication,
              lat: String(lat),
              lng: String(lng),
            };
            setTask((prevTask: any) => ({
              ...prevTask,
              ...location,
            }));
          }}
        />
      </div>

      <div className="flex flex-col gap-2">
        <Label htmlFor="attached_files">
          {t("tasks.create.form.attached_files.label")}
        </Label>

        {filesData.map((file, i) => (
          <div className="flex items-center gap-2" key={i}>
            <a
              href={`${baseURL}/v1/blob/file/${file.key}`}
              key={i}
              className="underline hover:no-underline text-purple-500"
              target="_BLANK"
            >
              <p>{file.name}</p>
            </a>
            <XIcon
              className="w-6 h-6 cursor-pointer"
              onClick={() => {
                setTask((prevTask: any) => ({
                  ...prevTask,
                  attached_files: prevTask.attached_files.filter(
                    (attachedFile: any) => attachedFile !== file.key
                  ),
                }));
                setFilesData(
                  filesData.filter(
                    (attachedFile) => attachedFile.key !== file.key
                  )
                );
              }}
            />
          </div>
        ))}

        <input
          type="file"
          ref={fileRef}
          className="hidden"
          onChange={uploadFile}
        />

        <Button
          disabled={isFileLoading}
          onClick={() => fileRef.current!.click()}
        >
          {t(
            isFileLoading
              ? "tasks.create.form.attached_files.loading"
              : "tasks.create.form.attached_files.add"
          )}
        </Button>
      </div>

      <div className="flex flex-col">
        {task.childs.map((child: any, i: number) => (
          <TaskComponent
            key={i}
            task={child}
            setTask={(newChild: any) => updateChildTask(i, newChild)}
            removeTask={() => removeChildTask(i)}
            isChild
            isError={isError}
            projects={projects}
          />
        ))}

        <div className="flex gap-2">
          <Button onClick={addChild}>{t("tasks.create.child.new")}</Button>

          {isChild && (
            <Button onClick={removeMe}>{t("tasks.create.child.remove")}</Button>
          )}
        </div>
      </div>
    </div>
  );
}

export function CreateTask() {
  const { t } = useTranslation();
  const { toast } = useToast();

  const [formData, setFormData] = useState(template);
  const [isError, setIsError] = useState(false);

  const [_, setLocation] = useLocation();
  const params = useSearch();

  const parent_id = new URLSearchParams(params).get("parent_id") ?? null;

  console.log(parent_id);

  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit() {
    if (isLoading) return;

    setIsLoading(true);

    try {
      const result = validator.safeParse(formData);

      if (!result.success) {
        setIsError(true);
      }

      const res = await authedFetch("/v1/task/create", {
        method: "POST",
        body: JSON.stringify({
          ...formData,
          lang: t("lang"),
          ...(parent_id &&
            parent_id.length > 0 && { parent_task_id: parent_id }),
        }),
      });

      if (res.ok) {
        toast({
          title: t("tasks.create.good"),
        });

        setLocation("/");
      } else {
        toast({
          title: t("tasks.create.bad"),
        });
      }
    } catch (e) {
      toast({
        title: t("tasks.create.bad"),
      });
    } finally {
      setIsLoading(false);
    }
  }

  const { data, error } = useSWR<{
    projects: {
      id: string;
      name: string;
      photo: string;
      members: { id: string; name: string; photo: string; caption: string }[];
    }[];
  }>("/v1/dashboard/create-task/payload", fetcher);

  if (error) {
    return <FetchError />;
  }

  if (!data) {
    return <Loader />;
  }

  return (
    <section className="h-full max-w-3xl mb-4">
      <header>
        <h1 className="mt-4 scroll-m-20 text-3xl font-semibold tracking-tight first:mt-0 mr-2">
          {t("tasks.create.title")}
        </h1>
      </header>
      <div>
        <TaskComponent
          task={formData}
          setTask={setFormData}
          removeTask={() => {}}
          isError={isError}
          projects={data.projects}
        />
        <Button onClick={onSubmit} disabled={isLoading}>
          {t(!isLoading ? "tasks.create.submit" : "tasks.create.loading")}
        </Button>
      </div>
    </section>
  );
}
