import { Checkbox } from "@/components/ui/checkbox";
import { useTaskSheet } from "@/lib/sheet";
import { useTranslation } from "react-i18next";

export function FiltersSelector() {
  const { t } = useTranslation();
  const { filters, setFilters } = useTaskSheet();

  return (
    <div className="flex items-center gap-4">
      <div className="flex items-center space-x-2">
        <Checkbox
          id="pending"
          checked={filters.pending}
          onCheckedChange={(checked) =>
            setFilters({ ...filters, pending: !!checked })
          }
        />
        <label
          htmlFor="pending"
          className="font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          {t("calendar.options.PENDING")}
        </label>
      </div>
      <div className="flex items-center space-x-2">
        <Checkbox
          id="inProgress"
          checked={filters.inProgress}
          onCheckedChange={(checked) =>
            setFilters({ ...filters, inProgress: !!checked })
          }
        />
        <label
          htmlFor="inProgress"
          className="font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          {t("calendar.options.IN_PROGRESS")}
        </label>
      </div>
      <div className="flex items-center space-x-2">
        <Checkbox
          id="done"
          checked={filters.done}
          onCheckedChange={(checked) =>
            setFilters({ ...filters, done: !!checked })
          }
        />
        <label
          htmlFor="done"
          className="font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          {t("calendar.options.DONE")}
        </label>
      </div>
    </div>
  );
}
