import { Task } from "@/types/task";
import { isBefore } from "date-fns";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { useTranslation } from "react-i18next";
import { cn } from "@/lib/utils";

export function TaskCard({
  task,
  detail,
  scope = "by-me",
  onClick,
}: {
  task: Task;
  detail: "title" | "user" | "full";
  scope?: "me" | "by-me";
  onClick?: () => void;
}) {
  const { t } = useTranslation();

  const isOverdue = isBefore(
    new Date(task.deadline),
    task.status.startsWith("DONE")
      ? new Date(task.finished_at ?? new Date().getTime())
      : new Date()
  );

  const isClickable = !!onClick;

  const colors = {
    PENDING: "gray",
    IN_PROGRESS: "yellow",
    DONE: "green",
    DONE_WITH_ISSUES: "orange",
  };

  const userData = scope === "me" ? task.assigned_by : task.assigned_to;

  return (
    <article
      className={cn(
        `bg-${colors[task.status]}-500`,
        isClickable &&
          `hover:bg-${
            colors[task.status]
          }-600 cursor-pointer transition-colors`,
        isOverdue && `border-l-8 border-red-600`,
        `text-white p-1 rounded mb-1 max-w-sm`
      )}
      onClick={onClick}
    >
      <p
        className={`text-lg font-semibold ${
          detail !== "title" ? "border-b border-gray-100" : ""
        }`}
      >
        {task.title}
      </p>
      {detail !== "title" && (
        <div className="flex items-center gap-1 mt-2">
          {userData?.photo && (
            <Avatar className="w-6 h-6">
              <AvatarImage src={userData.photo} />
              <AvatarFallback>{userData.name[0].toUpperCase()}</AvatarFallback>
            </Avatar>
          )}
          <p>
            {t(`calendar.week.card.${scope}`, {
              name: userData?.name ?? t("dead"),
            })}
          </p>
        </div>
      )}
    </article>
  );
}
