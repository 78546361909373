import { ActivateAccount } from "@/components/activate-account";
import { Freeze } from "@/components/freeze";
import { ShowWithPermissions } from "@/components/permissions";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { useAuth } from "@/lib/auth";
import { authedFetch } from "@/lib/fetcher";
import {
  CreditCardIcon,
  ExternalLinkIcon,
  GlobeIcon,
  HomeIcon,
  Loader2Icon,
  SettingsIcon,
  ShieldIcon,
  UsersIcon,
  Building2Icon,
  CalendarIcon,
  HandshakeIcon,
  NotebookPenIcon,
  SquareKanbanIcon,
} from "lucide-react";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "wouter";

function DashLink({ href, children }: { href: string; children: ReactNode }) {
  const [location] = useLocation();

  return (
    <Link
      className={
        (href === "/" ? location === href : location.startsWith(href))
          ? "flex items-center gap-3 rounded-lg bg-purple-100 px-3 py-2 text-purple-600  transition-all hover:text-purple-600"
          : "flex items-center gap-3 rounded-lg px-3 py-2 text-purple-500 transition-all hover:text-purple-900"
      }
      href={href}
    >
      {children}
    </Link>
  );
}

function DashButton({
  onClick,
  children,
}: {
  onClick: () => void;
  children: ReactNode;
}) {
  return (
    <button
      className="flex items-center gap-3 rounded-lg px-3 py-2 text-purple-500 transition-all hover:text-purple-900"
      onClick={onClick}
    >
      {children}
    </button>
  );
}

function PortalLink() {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  async function action() {
    if (isLoading) return;

    setIsLoading(true);

    const activate = await authedFetch("/v1/payments/portal", {});

    const data = await activate.json();

    if (data?.url) window.open(data.url, "_blank", "noreferrer");

    setIsLoading(false);
  }

  return (
    <DashButton onClick={action}>
      <div className="w-full flex items-center justify-between">
        <div className="w-full flex items-center gap-3">
          <CreditCardIcon className="h-4 w-4" />
          {t("navigation.payments")}
        </div>
      </div>
      {isLoading ? (
        <Loader2Icon className="h-4 w-4 animate-spin" />
      ) : (
        <ExternalLinkIcon className="h-4 w-4" />
      )}
    </DashButton>
  );
}

export function Dashboard({ children }: { children: ReactNode }) {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const { user, logout } = useAuth();

  return (
    <div className="flex h-screen w-full overflow-hidden md:rounded-r-3xl">
      <aside className="flex flex-col h-full w-16 border-r bg-purple-100/40 border-purple-200/40  md:w-60">
        <div className="flex flex-col justify-between min-h-screen gap-2">
          <div>
            <header className="flex h-[60px] items-center px-6">
              <div className="flex items-center gap-2 font-semibold">
                <img
                  src="https://assets.pulpoo.com/pulpoo.png"
                  alt=""
                  className="h-6 w-6"
                />
                <p className="text-purple-700">Pulpoo</p>
              </div>
            </header>
            <div className="flex-1">
              <nav className="grid items-start px-4 text-sm font-medium">
                <DashLink href="/">
                  <HomeIcon className="h-4 w-4" />
                  {t("navigation.home")}
                </DashLink>
                <DashLink href="/tasks">
                  <SquareKanbanIcon className="h-4 w-4" />
                  {t("navigation.tasks")}
                </DashLink>
                <DashLink href="/calendar">
                  <CalendarIcon className="h-4 w-4" />
                  {t("navigation.calendar")}
                </DashLink>
                <ShowWithPermissions minimun="MODERATOR">
                  <DashLink href="/projects">
                    <NotebookPenIcon className="h-4 w-4" />
                    {t("navigation.projects")}
                  </DashLink>
                  <DashLink href="/employees">
                    <HandshakeIcon className="h-4 w-4" />
                    {t("navigation.employees")}
                  </DashLink>

                  <Separator className="my-2 bg-purple-500" />

                  <DashLink href="/org">
                    <Building2Icon className="h-4 w-4" />
                    {t("navigation.org")}
                  </DashLink>
                  <ShowWithPermissions minimun="ADMIN">
                    <DashLink href="/users">
                      <UsersIcon className="h-4 w-4" />
                      {t("navigation.users")}
                    </DashLink>
                    {user?.org.is_activated && <PortalLink />}
                    <DashLink href="/settings">
                      <SettingsIcon className="h-4 w-4" />
                      {t("navigation.settings")}
                    </DashLink>
                  </ShowWithPermissions>
                </ShowWithPermissions>

                <Separator className="my-2 bg-purple-500" />

                <ShowWithPermissions minimun="SUPERADMIN">
                  <DashLink href="/superadmin">
                    <ShieldIcon className="h-4 w-4" />
                    {t("navigation.superadmin")}
                  </DashLink>
                </ShowWithPermissions>
                <DashButton
                  onClick={() =>
                    changeLanguage(language === "en" ? "es" : "en")
                  }
                >
                  <GlobeIcon className="h-4 w-4" />
                  {t("changeLanguage")}
                </DashButton>
              </nav>
            </div>
          </div>
          <footer className="flex flex-col items-center px-4 pb-4">
            <div className="flex items-start gap-4">
              <div className="relative inline-block">
                <Avatar className="border w-12 h-12">
                  <AvatarImage src={user?.photo!} />
                  <AvatarFallback>
                    {user?.name![0].toUpperCase()}
                  </AvatarFallback>
                </Avatar>
                <Avatar className="border rounded h-6 w-6 absolute -bottom-1 -right-1">
                  <AvatarImage src={user?.org.photo!} />
                  <AvatarFallback>
                    {user?.org?.name[0].toUpperCase()}
                  </AvatarFallback>
                </Avatar>
              </div>
              <div className="flex flex-col">
                <p className="font-semibold">{user?.name}</p>
                <p className="text-sm text-purple-600">{user?.org.name}</p>
              </div>
            </div>
            <Button className="mt-4 w-full" onClick={logout}>
              {t("navigation.header.logout")}
            </Button>
          </footer>
        </div>
      </aside>
      <main className="flex-1 flex flex-col overflow-auto p-4 md:p-6">
        {user?.org.is_freeze ? (
          <Freeze />
        ) : !user?.org.is_activated ? (
          <ActivateAccount />
        ) : (
          children
        )}
      </main>
    </div>
  );
}
